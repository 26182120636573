'use client';

import { createContext, useContext, useState } from "react";

const OpenContext = createContext({})

export const OpenContextProvider = ({ children }) => {
    const [open, setOpen] = useState(false);

    return (
        <OpenContext.Provider value={{ open, setOpen }}>
            {children}
        </OpenContext.Provider>
    )
};

export const useOpenContext = () => useContext(OpenContext);