import React from "react";
import "./style.css";
function Info() {
  return (
    <div className="info_wrapper">
      <div className="info_container">
        <div className="box_info">
          <div className="left_info">
            <div className="title_info ">Compromisso com o menor custo</div>
            <div className="text_defualt">
              <br />A <strong>DATABACKUP</strong> tem o compromisso de praticar
              o menor preço de backup em nuvem do mercado brasileiro. É uma
              ótima notícia para as pequenas empresas e revendas.
              <br />
              <br />
              Como atingimos essa meta?
              <br />
              <br />
              <span>
                <p>1</p>
              </span>
              Não cobramos pelo licenciamento dos softwares-agentes – aqueles
              que rodam no lado do cliente e são responsáveis por automatizar a
              sincronização dos dados. Você pode instalar em quantos
              computadores desejar. O preço é definido apenas em função do
              espaço em nuvem contratado para backup.
              <br />
              <br />
              <span>
                <p>2</p>
              </span>
              Possuímos nuvem de dados própria com segurança total e
              disponibilidade de 99,982%. Isso evita que utilizemos nuvem de
              terceiros que encarecem o custo final sem benefícios extras.
              <br />
              <div className="container_contact_whatsapp">
                <a
                  href="https://web.whatsapp.com/send?phone=5531996591205&text=Ol%C3%A1!Gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20de%20backup%20de%20dados"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button>Outras informações:</button>
                </a>

                <a
                  href="https://web.whatsapp.com/send?phone=5531996591205&text=Ol%C3%A1!Gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20de%20backup%20de%20dados"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="icon_whatsapp"
                    src="https://img.icons8.com/color/48/000000/whatsapp--v1.png"
                    alt="whatsapp"
                  />
                  (31) 99659.1205
                </a>
              </div>
            </div>
          </div>
          {/* <div className="right_info">
            <img src={chat} alt="chat" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Info;
