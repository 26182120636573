import React from "react";
import "./style.css";

export default function Payments() {

    return (
      <div className="price_container" id="planos" >

        <div className="price_wrapper">
          <div className="title_default">
            <h1>Planos Espaço para Armazenamento</h1>
            <h3 className="text_defualt">
              Pague apenas pelo espaço contratado. Não cobramos pelo número de
              computadores protegidos, assim você pode fazer backup em nuvem de
              todos os seus servidores, além de notebooks e outros computadores
              importantes.
            </h3>
            <div className="line"></div>
          </div>

          <div className="box_pricing">
            <div className="box">
              <div className="plano_pricing">250 Gb</div>
              <div className="box_top">
                <div className="cliente_final">
                  <span>Business 1</span>
                </div>
                <div className="preco">
                  <span>R$</span>173,20
                </div>
              </div>
              <div className="box_meio">
                <div className="option">
                  <div className="description">computadores</div>
                  <div className="icon">
                    ilimitados{" "}
                    <i className="fas fa-check" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="box">
              <div className="plano_pricing">1 Tb</div>
              <div className="box_top">
                <div className="cliente_final">
                  <span>Business 2</span>
                </div>
                <div className="preco">
                  <span>R$</span>333,20
                </div>
              </div>
              <div className="box_meio">
                <div className="option">
                  <div className="description">computadores</div>
                  <div className="icon">
                    ilimitados{" "}
                    <i className="fas fa-check" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="box">
              <div className="plano_pricing">2 Tb</div>
              <div className="box_top">
                <div className="cliente_final">
                  <span>Business 3</span>
                </div>
                <div className="preco">
                  <span>R$</span>533,20
                </div>
              </div>
              <div className="box_meio">
                <div className="option">
                  <div className="description">computadores</div>
                  <div className="icon">
                    ilimitados{" "}
                    <i className="fas fa-check" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom_text">
            <p>Preços especias para revenda</p>
            <a
              href="https://web.whatsapp.com/send?phone=5531996591205&text=Ol%C3%A1!Gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20de%20backup%20de%20dados"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="icon_whatsapp"
                src="https://img.icons8.com/color/48/000000/whatsapp--v1.png"
                alt="whatsapp"
              />
              <h3>(31) 99659.1205</h3>
            </a>
          </div>
        </div>
      </div>
    );
}
