import React from "react";

import "./style.css";
import cloud from "../../../assets/img/img_default/Group-519-1.svg";
function Banner() {
  return (
    <div className="Banner_wrapper" id="inicio">
      <div className="banner_container">
        <div className="left_wrapper">
          <div className="title_default">
            <span>Backup</span> em nuvem para empresas
          </div>
          <p className="text_defualt">
            O DATABACKUP é uma ferramenta de backup destinada a empresas que
            buscam manter a proteção e segurança dos seus dados usando backup em
            nuvem. É totalmente automatizado, simples operação, criptografia em
            todas as camadas e opera com servidores nas plataformas Windows, Mac
            OS e Linux. Melhor preço do mercado para pequenas e médias empresas.
          </p>
        </div>
        <div className="right_wrapper">
          <img src={cloud} alt="cloud" />
        </div>
      </div>
    </div>
  );
}

export default Banner;
