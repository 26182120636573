import React from "react";

import "./style.css";
import Banner from "../../Components/Banner";
import Funcionalidades from "../../Components/Funcionalidades";
import Datacenter from "../../Components/Datacenter";
import Support from "../../Components/Support";
import Revenda from "../../Components/Revenda";
import Payments from "../../Components/Payments";
import Formulario from "../../Components/Formulario";
import Info from "../../Components/Info";
import Testimonials from "../../Components/Testimonials";
function Home() {

  return (
    <div className="home_wrapper">
      <Banner />
      <Funcionalidades />
      <Testimonials />
      <Datacenter />
      <Support />
      <Revenda />
      <Payments />
      <Info />
      <Formulario />
    </div>
  );
}

export default Home;
