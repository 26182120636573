import React from "react";
import LogoFooter from "../../../assets/img/logoFooter.svg";
import facebook from "../../../assets/img/facebook-f-brands.svg";
import instagram from "../../../assets/img/instagram-brands.svg";
import youtube from "../../../assets/img/youtube-brands.svg";
import linkedin from "../../../assets/img/linkedin-in-brands.svg";
import "./style.css";
export default function Footer() {
  return (
    <>
      <div className="footer_wrapper" id="contato">
        <div className="logo_footer_container">
          <div className="area_nav_footer_container">
            <div className="logo_wrapper">
              <img src={LogoFooter} alt="logomarca rodapé" width="150" />
              <div className="end">
                ring to the table win-win survival strategies to ensure
                proactive domination. At the end of the day, going forward, a
              </div>
            </div>

            <div className="area_nav_footer">
              <div className="title">INSTITUCIONAL</div>
              <div className="topico">NOSSA HISTORIA</div>
              <div className="topico">ONDE ATUAMOS</div>
              <div className="topico"><a href="https://databackup.com.br/blog-dicas-noticias">NOTICIAS</a></div>
              <div className="topico"><a href="https://databackup.com.br/blog-dicas-noticias">DICAS DATABACKUP</a></div>

            </div>

            <div className="area_nav_footer">
              <div className="title">SUPORTE</div>
              <div className="topico">PORTAL DO CLIENTE</div>
              <div className="topico">FAQ</div>
              <div className="topico">WIKI</div>
              <div className="topico">STATUS DOS SERVIÇOS</div>

            </div>

                       <div className="area_nav_footer">
              <div className="title">PRODUTOS</div>
              <div className="topico">BACKUP EM NUVEM</div>

            </div>

            <div className="social_container">
              <div className="title">contatos</div>
              <a
                href="https://web.whatsapp.com/send?phone=5531997090701&text=Ol%C3%A1!Gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20de%20backup%20de%20dados"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="topico">
                  Suporte técnico: <span>(31) 9 9709.0701</span>{" "}
                  <i className="fab fa-whatsapp"></i>
                </div>
              </a>
              <a
                href="https://web.whatsapp.com/send?phone=5531996591205&text=Ol%C3%A1!Gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20de%20backup%20de%20dados"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="topico">
                  Vendas <span>(31) 9 9659.1205</span>{" "}
                  <i className="fab fa-whatsapp"></i>
                </div>
              </a>
              <div className="topico">
                Financeiro <span>(31) 3211.7730</span>
              </div>

              <div className="socila_icons">
                <img src={facebook} alt="facebook" width="15" />
                <img src={instagram} alt="facebook" width="20" />
                <img src={youtube} alt="facebook" width="20" />
                <img src={linkedin} alt="facebook" width="20" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="endereco_wrapper">
      <strong>DATABACKUP&nbsp;| Tecnologia da Informação Ltda
Rua Boaventura, 1621</strong> &nbsp;– Bairro Jaraguá, Belo Horizonte | MG – 31.270-300
<strong>&nbsp;&nbsp;CNPJ :&nbsp;&nbsp;</strong> 34.896.186/0001-20

      </div>
      <div className="subFooter">
        Todos os direitos reservados @2022 – Databackup
      </div>
    </>
  );
}
