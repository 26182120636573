import React from "react";

import "./style.css";
import rev from "../../../assets/img/img_default/Group-518-1.svg";
function Revenda() {
  return (
    <div className="revenda_wrapper">
      <div className="revenda_container">
        <div className="box_revenda">
          <div className="left_area">
            <img src={rev} alt="revenda"></img>
          </div>
          <div className="right_area">
            <div className="title_box_revenda">
              Revendas e programa de afiliados
            </div>
            <div className="description">
              Empresas que prestam serviços de TI, manutenção de computadores ou
              assemelhados podem se tornar uma REVENDA DATABACKUP na região onde
              atua. É uma excelente oportunidade aumentar a receita recorrente e
              o portfólio de serviços.
              <br />
              <br />
              Pequenas empresas, trabalhadores autônomos e pessoas físicas em
              geral que desejam ter uma receita extra podem participar do nosso
              programa de afiliados. Nele, existe o pagamento de ótimas
              bonificações por cliente indicado.
              <br />
              <br />
              <div className="whatsapp_container">
                Converse com nossa equipe de vendas pelo Whatsapp:
                <a
                  href="https://web.whatsapp.com/send?phone=5531996591205&text=Ol%C3%A1!Gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20de%20backup%20de%20dados"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="icon_whatsapp"
                    src="https://img.icons8.com/color/48/000000/whatsapp--v1.png"
                    alt="whatsapp"
                    rel="noopener noreferrer"
                  />
                  (31) 99659.1205
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Revenda;
