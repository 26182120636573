import React from "react";

import "./style.css";
import chat from "../../../assets/img/imgd.png";
function Support() {
  return (
    <div className="support_wrapper">
      <div className="support_container">
        <div className="box_support">
          <div className="left_support">
            <div className="title_support ">Atendimento técnico</div>
            <div className="text_defualt">
              <br />• De segunda à sexta-feira: 8h às 22h
              <br />• Aos sábados de 8h às 17h
              <div className="contact_whatsapp">
                • Telefones: (31) 3211.7727 &nbsp;{" "}
                <a
                  href="https://web.whatsapp.com/send?phone=5531997090701&text=Ol%C3%A1!Gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20de%20backup%20de%20dados"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="icon_whatsapp"
                    src="https://img.icons8.com/color/48/000000/whatsapp--v1.png"
                    alt="whatsapp"
                  />
                  (31) 9 9709.0701
                </a>
              </div>
            </div>
          </div>
          <div className="right_support">
            <img src={chat} alt="chat" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
