import React from "react";

import "./style.css";
import img from '../../../assets/img/img_default/Group-1373.svg'
function Datacenter() {
  return (
    <div className="datacenter_wrapper">
      <div className="datacenter_container">
        <div className="box_datacenter">
          <div className="title_datacenter">Datacenter</div>
          <div className="description text_defualt">
            O datacenter da DATABACKUP possui diversas certificações
            internacionais que visam garantir a segurança das informações dos
            clientes e a prestação continuada dos serviços:
            <br />
            - Certificação Tier III <br />
            - Certificação ISAE 3402 <br />
            - Certificação SSL/TLS <br />
            Nosso uptime atual é de 99,982% e os sistemas de criptografia
            trabalham com chaves de 256 bits.
          </div>
        </div>
      <div className="img_right">
        <img src={img} alt="datacenter"/>
      </div>
      </div>
    </div>
  );
}

export default Datacenter;
