import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import firebase from "firebase/app";
import "firebase/firestore";
import "./style.css";
export default function Testimonials() {
  const [data, setData] = useState([]);
  useEffect(() => {
    // firebase
    //   .firestore()
    //   .collection("Depoimentos")
    //   .get()
    //   .then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {

    //       setData([doc.data()]);
    //     });
    //   });

    firebase.firestore()
      .collection("Depoimentos")
      .onSnapshot((query) => {
        const list = [];
        query.forEach((element) => {
          list.push({ ...element.data(), id: element.id });
        });

        setData(list);
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,

    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
  };
  return (
    <div className="depoimentos_wrapper">
      <div className="title_default">
        <h1>Depoimentos de Clientes e Revenda</h1>
        {/* <h3 className="text_defualt">
            Veja o que dizem os nossos parceiros sobre a revenda Databackup!
          </h3> */}
        <div className="line"></div>
      </div>

      <Slider className="slider" {...settings}>
        {data.map((dep) => {
          return (
            <div key={dep.id} className="depoimentos_box">
              <div
                  style={{ display: "inline-block" }}
                  className="box_depoimentos_content"
                >
              <div className="autor">{dep.cliente}</div>
              <div className="funcao">{dep.data}</div>
              <div className="depoimento">{dep.depoimento}</div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
