import React, { useState } from "react";

import "./style.css";
import axios from "axios";
import qs from "qs";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

function Formulario() {
  const [nome, setNome] = useState();
  const [email, setEmail] = useState();
  const [senha, setSenha] = useState();
  const [cofirmeSenha, setCofirmeSenha] = useState();
  const [telefone, setTelefone] = useState();
  const [erro, setErro] = useState();
  const [success, setSuccess] = useState();
  const [open, setOpen] = useState(false);
  function cadastrar() {
    if (!nome || !email || !senha || !cofirmeSenha || !telefone) {
      setErro("Preencha todos os campos!");
      setTimeout(() => {
        setErro("");
      }, 3000);
    } else if (senha !== cofirmeSenha) {
      setErro("Senhas diferentes");
      setTimeout(() => {
        setErro("");
      }, 3000);
    } else {
      var data = qs.stringify({
        token:
          "550b83$ec&7c&dbd264.4611-6c81295c88ff9258Ted.jsHkH!l65LopIt%REc#05233c1)02dtbe96376",
        nomecontato: nome,
        email: email,
        senha: senha,
        "confirmar-senha": cofirmeSenha,
        telefone: telefone
      });
      var config = {
        method: "post",
        url: "https://app.databackup.com.br/clientes/cadastroApi",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          console.log(response);
          if (response.status === 201) {

            console.log(nome , email, senha, telefone)
            setTimeout(() => {
              setOpen(true);
            }, 500);
            setSuccess("Usuario cadastrado com sucesso");
            // setTimeout(() => {
            //   window.location.href = "https://app.databackup.com.br/";
            // }, 5000);
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.status === 400) {
            setErro("Usuario já cadastrado");
          }
        });
    }
  }
  return (
    <div className="formulario_wrapper" id="teste">
      <Popup open={open} modal nested>
        {(close) => (
          <div className="modal">
            <div className="success">Usuario cadastrado com sucesso</div>
            <div className="content">
              Você será redirecionado para a pagina de acesso e receberá um
              e-mail com todas as informações importantes para o passo!
            </div>
          </div>
        )}
      </Popup>
      <div className="formulario_container">
        <div className="box_formulario">
          <div className="text_form">
            <h1>FAQ</h1>
            <div className="info_text">Período de testes:</div>
            <div className="text_defualt">
              Você poderá testar o produto por até 7 dias na versão completa,
              sem limites de funcionalidades ou espaço de armazenamento.
            </div>
            <div className="info_text">Cobrança</div>
            <div className="text_defualt">
              Não haverá faturamento ou emissão de NF durante o período de
              testes.
            </div>
            <div className="info_text">Contratação do serviço:</div>
            <div className="text_defualt">
              O cliente dará o “aceite” para contratação do serviço através de
              mensagem por e-mail enviada pela nossa gerência comercial.
            </div>
            <div className="info_text">Pagamento</div>
            <div className="text_defualt">
              O pagamento ocorre após a prestação do serviço, de forma mensal,
              através de boleto bancário e vencimento dias 10 ou 22 do mês. Não
              há prazo de fidelidade.
            </div>
          </div>
          <form>
            <div className="text_teste">Teste grátis agora mesmo</div>
            <input
              type="text"
              className="form-control is-invalid"
              placeholder="Nome completo"
              onChange={(e) => {
                setNome(e.target.value);
              }}
            />
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <input
              type="password"
              className="form-control"
              placeholder="Senha"
              onChange={(e) => {
                setSenha(e.target.value);
              }}
            />

            <input
              type="password"
              className="form-control"
              placeholder="Confirme sua Senha"
              onChange={(e) => {
                setCofirmeSenha(e.target.value);
              }}
            />

            <input
              type="text"
              className="form-control"
              placeholder="Telefone"
              onChange={(e) => {
                setTelefone(e.target.value);
              }}
            />
            <div className="erro_message">{erro}</div>
            <div className="success_message">{success}</div>
            <div
              onClick={() => cadastrar()}
              className="btn btn-primary btn_cadastro"
            >
              Iniciar Teste Grátis
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Formulario;
