import React from "react";

import "./style.css";
import ransom from "../../../assets/img/img_default/Group-514-1.svg";
import cloud3 from "../../../assets/img/img_default/Group-515-1.svg";
import iconimg from "../../../assets/img/img_default/Group-520-1.svg";
import Faq from "react-faq-component";
function Funcionalidades() {
  const data = {
    // title: "FAQ (How it works)",
    rows: [
      {
        title: " + Outras Funcionalidades.",
        content: ` • Servidores, notebooks e estações de trabalho.<br />
            • Ambientes: Windows, MAC OS, Linux.<br />
            • Aviso de de alerta em caso de problemas na sincronização.<br />
            • Backup de arquivos abertos.<br />
            • Arquivos deletados permanecem por 30 dias.<br />
            • Controle de banda.<br />`
      }
    ]
  };
  const styles = {
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "blue",
    rowContentColor: "#606C7A"
    // arrowColor: "red",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
  };

  return (
    <div className="funcionalidades_wrapper" id="beneficios">
      <div className="funcionalidades_container">
        <div className="box_wrapper">
          <div className="box_left">
            <div className="title_funcionalidades">Benefícios</div>
            <div className="text text_defualt">
              • Garante a integridade do patrimônio digital da empresa.
              <br />
              • Elimina a perda de dados devido a ações de hackers e erros
              humanos.
              <br />
              <strong>
                • Protege dados de servidores, desktops e notebooks importantes.
              </strong>
              <br />
              • Mantém dados disponíveis, mesmo com servidor “fora do ar”.
              <br />
              • Evita danos à imagem da empresa por chantagem de hackers.
              <br />
              • Reduz custos com infraestrutura local.
              <br />
            </div>
          </div>
          <div className="box_right">
            <img src={iconimg} alt="icon" />
          </div>
        </div>

        <div className="box_wrapper">
          <div className="box_left">
            <div className="title_funcionalidades">Funcionalidades</div>
            <div className="text text_defualt">
              • Backup em tempo real ou agendado.
              <br />
              • Operação automatizada.
              <br />
              • Backup completo e incremental.
              <br />
              • Restauração do backup através de ambiente web.
              <br />
              • Mantém últimas 30 versões dos arquivos.
              <br />
              • Visualização dos arquivos do backup através de smartphones.
              <br />
              • Pode ser personalizado para revendas.
              <br />
              <Faq data={data} styles={styles} config={config} />
            </div>
          </div>
          <div className="box_right">
            <img src={cloud3} alt="icon" />
          </div>
        </div>

        <div className="box_wrapper">
          <div className="box_left">
            <div className="title_funcionalidades">
              Proteção contra Ransomware
            </div>
            <div className="text text_defualt">
              A solução de backup em nuvem da Databackup mantém as últimas 30
              versões dos arquivos salvos em nuvem própria, por isso, em caso de
              infecção no equipamento local, basta restaurar a última versão do
              arquivo da nuvem.
              <br /> <strong>Como se proteger:</strong>
              <br /> A solução Databackup mantém as últimas 30 versões dos
              arquivos salvos em nuvem, por isso, em caso de infecção no
              equipamento local, basta restaurar a última versão do arquivo da
              nuvem.
              <div className="contact_whatsapp">
                Outras informações:
                <a
                  href="https://web.whatsapp.com/send?phone=5531996591205&text=Ol%C3%A1!Gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20de%20backup%20de%20dados"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="icon_whatsapp"
                    src="https://img.icons8.com/color/48/000000/whatsapp--v1.png"
                    alt="whatsapp"
                  />
                  <h3>(31) 99659.1205</h3>
                </a>
              </div>
            </div>
          </div>
          <div className="box_right">
            <img src={ransom} alt="icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Funcionalidades;
