import "./App.css";
import Home from "./src/Pages/Home";
import MenuB from "./src/Components/MenuB";
import Footer from "./src/Components/Footer";
import { OpenContextProvider } from "./Context/store";
function App() {
  return (
    <div className="App">
      <OpenContextProvider>
      <MenuB/>
      <Home />
      <Footer/>
      </OpenContextProvider>
    </div>
  );
}
  // "homepage" : "https://databackup.com.br/backup-empresas/" , 
export default App;
