import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { FirebaseAppProvider } from "reactfire";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export const firebaseConfig = {
  apiKey: "AIzaSyASS0W-5W5FWS1ZbzdaZ8ZfZGKPuKEgE3E",
  authDomain: "databackup-7eb0a.firebaseapp.com",
  projectId: "databackup-7eb0a",
  storageBucket: "databackup-7eb0a.appspot.com",
  messagingSenderId: "637154568772",
  appId: "1:637154568772:web:cb04db53fac1eee8af2a11"
};

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
