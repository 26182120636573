import React, { useState, useEffect } from "react";

import "./style.css";
import logo from "../../../assets/img/data.png";
// import AnchorLink from "react-anchor-link-smooth-scroll";
import { useOpenContext } from "../../../Context/store";
import $ from "jquery";
import Subscribe from "../Subscribe";
function MenuB() {
  const [scroll, setScroll] = useState(0);
  const [active, setActive] = useState("");

  useEffect(() => {
    window.onscroll = () => {
      setScroll(window.pageYOffset);
    };
    if (scroll > 10) {
      setActive("active");
      // setLogo(logob)
    } else {
      setActive("");
      // setLogo(logo)
    }
  }, [scroll]);

  useEffect(() => {
    $(".drag_menu_wrapper").click(() => {
      $(".menu_full").animate({ width: "toggle" });
      $(".menu_full").css({ display: "flex" });
    });
    $(".fechar").click(() => {
      $(".menu_full").animate({ width: "toggle" });
    });
    $(".item").click(() => {
      $(".menu_full").animate({ width: "toggle" });
    });
  }, []);

  const { setOpen } = useOpenContext();

  const [scrollHeight, setScrollHeight] = useState(0);

  const handleScroll = () => {
    const currentScrollHeight = window.scrollY;
    setScrollHeight(currentScrollHeight);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollHeight.toFixed(0) >= 3400) {
      if (localStorage.getItem("popup") === "true") {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  }, [scrollHeight, setOpen]);

  const openTop = () => {
    if (localStorage.getItem("popup") === "true") {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <Subscribe />
      <div className="menu_full">
        <div className="fechar">x</div>
        <div className="navigation">
          <a className="item" href="https://databackup.com.br/">
            inicio
          </a>
          <a className="item" href="https://databackup.com.br/">
            PARA EMPRESAS
          </a>
          <a
            className="item"
            href="https://databackup.com.br/revenda-backup-em-nuvem"
          >
            SEJA REVENDA
          </a>
          <div className="item">
            <a
              href="https://databackup.com.br/blog-dicas-noticias"
              target="_blank"
              rel="noopener noreferrer"
            >
              DICAS BACKUP
            </a>
          </div>
          <div className="item">
            <a
              href="https://databackup.com.br/blog-dicas-noticias"
              target="_blank"
              rel="noopener noreferrer"
            >
              NOTÍCIAS
            </a>
          </div>
          <div className="item">
            <a
              href="https://afiliados.databackup.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              indicação premiada
            </a>
          </div>
          <a className="item" href="#teste">
            teste grátis
          </a>
        </div>
      </div>
      <div className={`menu_wrapper ${active}`}>
        <div className="open_subscribe" onMouseEnter={() => openTop()}></div>
        <div className="menu_container">
          <div className="logo_wrapper">
            <img src={logo} alt="logo" />
          </div>
          <div className="nav_wrapper">
            <div className="navigation">
              <a href="https://databackup.com.br/">inicio</a>
              <a href="https://databackup.com.br/">PARA EMPRESAS</a>
              <a href="https://databackup.com.br/revenda-backup-em-nuvem">
                SEJA REVENDA
              </a>
              <div>
                <a
                  href="https://databackup.com.br/blog-dicas-noticias"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DICAS BACKUP
                </a>
              </div>
              <div>
                <a
                  href="https://databackup.com.br/blog-dicas-noticias"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NOTÍCIAS
                </a>
              </div>
              <div>
                <a
                  href="https://afiliados.databackup.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  indicação premiada
                </a>
              </div>

              <a href="#teste">teste grátis</a>
            </div>

            <div className="contact_wrapper">
              <div className="contact">
                <a
                  href="
                  https://web.whatsapp.com/send?phone=5531996591205&text=Ol%C3%A1!Gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20de%20backup%20de%20dados
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="icon_whatsapp"
                    src="https://img.icons8.com/color/48/000000/whatsapp--v1.png"
                    alt="whatsapp"
                  />
                  &nbsp;
                  <h3>(31) 99659-1205</h3>
                </a>
              </div>
            </div>

            <button>entre em contato</button>
          </div>
          <div className="drag_menu_wrapper">
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuB;
